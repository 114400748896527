import React from "react";
import { map } from "lodash";
import { connect } from "react-redux";
import Card from "../components/Card";
import AppContainer from "../components/AppContainer";
import withTranslation from "../hocs/withTranslation";
import { getStyles } from "../store/selectors";

const PrivacyPolicy = ({ T, appStyles }) => (
  <AppContainer.Content appStyles={appStyles}>
    <Card appStyles={appStyles}>
      <Card.Title appStyles={appStyles}>
        {T("Privacy Policy {{business_name}}")}
      </Card.Title>
      <Card.Content appStyles={appStyles}>
        {map(T("{{privacy}}").split("\\n"), (line) => (
          <div style={{ marginTop: 15 }}>{line}</div>
        ))}
      </Card.Content>
    </Card>
  </AppContainer.Content>
);

const mapStateToProps = (state, props) => {
  return {
    appStyles: getStyles(state, props),
  };
};

export default connect(mapStateToProps)(
  withTranslation(PrivacyPolicy, "Privacy"),
);
